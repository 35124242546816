<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Control de Junior</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            Formulario de Datos
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-autocomplete
                  @change="loadJuniors()"
                  label="Senior"
                  item-text="nameC"
                  item-value="id"
                  :items="itemsSenior"
                  v-model="id_senior"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  @change="valida()"
                  label="Número de DNI/RUC"
                  v-model="documento"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Nombres" v-model="nombres"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Apellidos" v-model="apellidos">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Teléfono" v-model="telefono">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Correo" v-model="correo"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Provincia" v-model="provincia"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Usuario" v-model="usuario"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Contraseña" v-model="contraseña">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-switch label="Estado del Senior" v-model="status"></v-switch>
              </v-col>
              <v-col cols="6" md="6">
                <v-btn block color="error" @click="cleanData()" rounded
                  >Cancelar</v-btn
                >
              </v-col>
              <v-col cols="6" md="6">
                <v-btn
                  block
                  v-if="statusData == 1"
                  color="success"
                  @click="addData()"
                  rounded
                  >Agregar</v-btn
                >
                <v-btn
                  block
                  v-if="statusData == 2"
                  color="success"
                  @click="updateData()"
                  rounded
                  >Actualizar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            Lista de Junior
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="itemsJunior"
            :search="search"
          >
            <template v-slot:item.pro="{ item }">
              <v-icon small class="mr-2" @click="viewPro(item.id)">
                mdi-package
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </template></v-data-table
          >
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_pro" width="70%">
      <v-card>
        <v-card-title> Productos </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                :items="itemsCampana"
                item-text="name"
                item-value="id"
                v-model="pro_campana"
                label="Campaña"
                @change="getCatalogo()"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                :items="itemsCatalogo"
                item-text="name"
                item-value="id"
                v-model="pro_catalogo"
                label="Catálogo"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="pro_nombre" label="Nombre"> </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-text-field
                type="number"
                v-model="pro_cantidad"
                label="Cantidad"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                type="number"
                v-model="pro_precio"
                label="Precio Unitario"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                type="text"
                rows="2"
                v-model="pro_observacion"
                label="Observación"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headersPro"
            :items="itemsPro"
            :search="searchPro"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2 mx-auto" @click="getProd(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon class="mr-2 mx-auto" @click="deletePro(item.id)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialog_pro = false">
            CERRAR
          </v-btn>
          <v-btn
            color="primary"
            v-if="statusPro == 1"
            text
            @click="AddProduct()"
          >
            AGREGAR
          </v-btn>
          <v-btn
            color="primary"
            v-if="statusPro == 2"
            text
            @click="editProduct()"
          >
            ACTUALIZAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Senior",
  data: () => ({
    search: "",
    itemsPais: [],
    itemsSenior: [],
    itemsJunior: [],
    id: "",
    id_senior: "",
    documento: "",
    nombres: "",
    apellidos: "",
    telefono: "",
    correo: "",
    provincia: "",
    usuario: "",
    contraseña: "",
    status: true,
    statusData: 1,
    dialog_pro: false,
    itemsCampana: [],
    itemsCatalogo: [],
    itemsPro: [],
    searchPro: "",

    pro_campana: "",
    pro_catalogo: "",
    pro_nombre: "",
    pro_cantidad: "",
    pro_precio: "",
    pro_cliente: "",
    pro_observacion: "",
    statusPro: 1,
    pro_id: "",

    headers: [
      {
        text: "DNI/RUC",
        align: "start",
        value: "documento",
      },
      { text: "Nombres", value: "nombres" },
      { text: "Apellidos", value: "apellidos" },
      { text: "Teléfono", value: "telefono" },
      { text: "Correo", value: "correo" },
      { text: "Provincia", value: "provincia" },
      /* { text: "Usuario", value: "usuario" }, */
      { text: "Pro.", value: "pro" },
      { text: "Acciones", value: "actions" },
    ],

    headersPro: [
      {
        text: "Producto",
        align: "start",
        value: "producto",
      },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Total", value: "total" },
      { text: "Observacion", value: "observacion" },

      { text: "Acción", value: "actions" },
    ],
  }),
  mounted() {
    this.loadPais();
    this.loadSeniors();
    this.getCampana();
  },
  methods: {
    getCampana() {
      axios.get(process.env.VUE_APP_API_URL + "campana_all").then((result) => {
        this.itemsCampana = result.data;
      });
    },
    getPro() {
      axios
        .get(process.env.VUE_APP_API_URL + "productos/j/" + this.pro_cliente)
        .then((result) => {
          this.itemsPro = result.data;
        });
    },
    getProd(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "productos/" + id)
        .then((result) => {
          this.pro_id = id;
          this.pro_campana = result.data[0].id_campana;
          this.getCatalogo();
          this.pro_catalogo = result.data[0].id_catalogo;
          this.pro_nombre = result.data[0].producto;
          this.pro_cantidad = result.data[0].cantidad;
          this.pro_precio = result.data[0].precio;
          this.pro_observacion = result.data[0].observacion;
          this.statusPro = 2;
        });
    },
    getCatalogo() {
      axios
        .get(process.env.VUE_APP_API_URL + "catalogos/p/" + this.pro_campana)
        .then((result) => {
          this.itemsCatalogo = result.data;
        });
    },
    loadPais() {
      axios.get(process.env.VUE_APP_API_URL + "pais").then((result) => {
        this.itemsPais = result.data;
      });
    },
    loadSeniors() {
      axios.get(process.env.VUE_APP_API_URL + "senior").then((result) => {
        this.itemsSenior = result.data;
      });
    },
    loadJuniors() {
      axios
        .get(process.env.VUE_APP_API_URL + "junior/s/" + this.id_senior)
        .then((result) => {
          this.itemsJunior = result.data;
        });
    },
    editItem(id) {
      axios.get(process.env.VUE_APP_API_URL + "junior/" + id).then((result) => {
        this.id = result.data[0].id;
        this.id_senior = result.data[0].id_senior;
        this.documento = result.data[0].documento;
        this.nombres = result.data[0].nombres;
        this.apellidos = result.data[0].apellidos;
        this.telefono = result.data[0].telefono;
        this.correo = result.data[0].correo;
        this.provincia = result.data[0].provincia;
        this.usuario = result.data[0].usuario;
        this.contraseña = result.data[0].contraseña;
        this.status = result.data[0].status;
        this.statusData = 2;
      });
    },
    cleanPro() {
      (this.pro_campana = ""),
        (this.pro_catalogo = ""),
        (this.pro_nombre = ""),
        (this.pro_precio = ""),
        (this.pro_observacion = ""),
        (this.pro_cantidad = ""),
        (this.statusPro = 1);
    },
    AddProduct() {
      let data = {
        cliente: this.pro_cliente,
        catalogo: this.pro_catalogo,
        orden: 1,
        codigo_producto: "",
        pagina: 0,
        producto: this.pro_nombre,
        cantidad: this.pro_cantidad,
        precio: this.pro_precio,
        observacion: this.pro_observacion,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "productos", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            //this.loadRSeniorSS(this.orden_orden);
            this.cleanPro();
            this.getPro();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              tipmer: 8000,
            });
          }
        });
    },
    editProduct() {
      let data = {
        cliente: this.pro_cliente,
        catalogo: this.pro_catalogo,
        orden: 1,
        codigo_producto: "",
        pagina: 0,
        producto: this.pro_nombre,
        cantidad: this.pro_cantidad,
        precio: this.pro_precio,
        observacion: this.pro_observacion,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/pro/" + this.pro_id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.cleanPro();
            this.getPro();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    deletePro(id) {
      axios
        .delete(process.env.VUE_APP_API_URL + "productos/" + id)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.cleanPro();
            this.getPro();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    viewPro(id) {
      this.pro_cliente = id;
      this.getPro();
      this.dialog_pro = true;
    },
    addData() {
      let data = {
        id_senior: this.id_senior,
        documento: this.documento,
        nombres: this.nombres,
        apellidos: this.apellidos,
        telefono: this.telefono,
        correo: this.correo,
        provincia: this.provincia,
        usuario: this.usuario,
        contraseña: this.contraseña,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "junior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Junior registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadJuniors();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    valida() {
      if (this.usuario === "" && this.contraseña === "") {
        this.usuario = this.documento;
        this.contraseña = this.documento;
      }
    },
    updateData() {
      let data = {
        id_senior: this.id_senior,
        documento: this.documento,
        nombres: this.nombres,
        apellidos: this.apellidos,
        telefono: this.telefono,
        correo: this.correo,
        provincia: this.provincia,
        usuario: this.usuario,
        contraseña: this.contraseña,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "junior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Junior actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadJuniors();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, El Junior no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "junior/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Junior eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadJuniors();
                this.cleanData();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El Junior no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.documento = "";
      this.nombres = "";
      this.apellidos = "";
      this.telefono = "";
      this.correo = "";
      this.provincia = "";
      this.contraseña = "";
      this.usuario = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
